import React, { useRef, useState } from "react";
import { saveAs } from "file-saver";

import { Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import AppConstants from "../../constants";

import { styles } from "./Dialog.styles";

interface RenderQRCodeDetailsModalInterface {
  classes: any;
  open: boolean;
  rowData: any;
  handleClose: any;
  loading: boolean;
  regeneratedQRCode: any;
  dialogRegenerateCodeHandler: any;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const RenderQRCodeDetailsModal = (props: RenderQRCodeDetailsModalInterface) => {
  const { classes, open, rowData, handleClose, loading, regeneratedQRCode, dialogRegenerateCodeHandler } = props;

  const PaperProps = { classes: { root: classes.hqrCodeDialogPaperPropsRoot } };

  const counter = useRef(0);
  const [isLoading, setIsLoading] = useState(true);

  const dialogActionHandler = () => {
    if (regeneratedQRCode) {
      saveAs(regeneratedQRCode, `${rowData.store}.jpg`);
    } else if (rowData?.hubQRImagePath) {
      saveAs(rowData && rowData.hubQRImagePath, `${rowData.store}.jpg`);
    }
  };

  const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle className={classes.root} {...other}>
        <Grid className={classes.titleSpanStyle}>{children}</Grid>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme: Theme) => ({ root: { padding: theme.spacing(0, 2, 0, 2), overflowX: "hidden" } }))(MuiDialogContent);
  const DialogActions = withStyles((theme: Theme) => ({ root: { margin: 0, padding: theme.spacing(2) } }))(MuiDialogActions);

  const handleImageLoaded = () => {
    counter.current += 1;
    if (counter.current === 1) {
      setIsLoading(!isLoading);
    }
  };

  return (
    <Dialog className={""} open={open} onClose={handleClose} PaperProps={PaperProps}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography className={classes.titleSpanStyle}>{`${rowData?.store} - ${rowData?.storeName}`}</Typography>
      </DialogTitle>
      <DialogContent id="customized-dialog-content" className="do-not-print">
        <Grid container className={classes.qrImageContainer}>
          <div style={{ display: isLoading ? "block" : "none" }}>Loading image</div>
          <img
            className={classes.qrImage}
            src={regeneratedQRCode ? regeneratedQRCode : rowData && rowData.hubQRImagePath ? rowData.hubQRImagePath : ""}
            onLoad={() => handleImageLoaded()}
            alt="QR-Code"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" alignItems="center" justify="space-evenly">
          <Grid item>
            <Button className="userBtn secondary" variant="contained" onClick={() => dialogActionHandler()}>
              {AppConstants.BUTTONS.DOWNLOAD_QR}
            </Button>
          </Grid>
          <Grid item>
            <Button className="userBtn primary" variant="contained" onClick={() => dialogRegenerateCodeHandler(rowData?.store || "")}>
              {AppConstants.BUTTONS.REGENERATE_QR}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(RenderQRCodeDetailsModal);
