import React from "react";
import { saveAs } from "file-saver";

import { Button, Grid } from "@material-ui/core";

import AppConstants from "../../constants/AppConstants";

interface RenderPOSQRCodeDetailsInterface {
  classes: any;
  rowData: any;
  regeneratedQRCode: any;
  dialogRegenerateCodeHandler: any;
}

const RenderPOSQRCodeDetails = (props: RenderPOSQRCodeDetailsInterface) => {
  const { classes, rowData, regeneratedQRCode, dialogRegenerateCodeHandler } = props;

  const dialogActionHandler = () => {
    if (regeneratedQRCode) {
      saveAs(regeneratedQRCode, `${rowData.store}.jpg`);
    } else if (rowData?.hubQRImagePath) {
      saveAs(rowData && rowData.hubQRImagePath, `${rowData.store}.jpg`);
    }
  };

  return (
    <Grid container className={classes.qrCodeMainContainer}>
      <Grid item xs={12} className={classes.qrCodeMainItem}>
        <img className={classes.qrImage} src={regeneratedQRCode ? regeneratedQRCode : rowData && rowData.hubQRImagePath ? rowData.hubQRImagePath : ""} alt="icons" />
      </Grid>
      <Grid container direction="row" alignItems="center" justify="space-evenly">
        <Grid item>
          <Button className={`${classes.dialogButtonsStyle} dialogBtn secondary`} variant="contained" onClick={() => dialogActionHandler()}>
            {AppConstants.BUTTONS.DOWNLOAD_QR}
          </Button>
        </Grid>
        <Grid item>
          <Button className={`${classes.dialogButtonsStyle} dialogBtn primary`} variant="contained" onClick={() => dialogRegenerateCodeHandler(rowData?.store)}>
            {AppConstants.BUTTONS.REGENERATE_QR}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderPOSQRCodeDetails);
