import React, { forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { TransitionProps } from "@material-ui/core/transitions";

import AppConstants from "../../constants/AppConstants";
import CustomDialog from "../../common/Dialog";
import RenderPOSDetails from "./RenderPOSDetails";
import RenderPOSQRCodeDetails from "./RenderPOSQRCodeDetails";
import SwitchButton from "../../common/SwitchButton/SwitchButton";
import TextBox from "../../common/TextBox";
import SelectBox from "../../common/SelectBox";
import CustomSnackbar from "../../common/CustomSnackbar";

import { Button, Grid, Slide, Typography } from "@material-ui/core";
import { isNull, isUndefined } from "../../utils/helpers.utils";
import { useDispatch } from "react-redux";
import { updateStoreRecordsSlice } from "StoreManagement/redux/StoreManagementSlice";
import { createUpdateStoreRecordsSlicePayload } from "./response.transforms";
import { AppState } from "../../config/redux/reducers";

interface RenderPOSDetailsDialogInterface {
  classes: any;
  open: boolean;
  rowData: any;
  handleClose: any;
  handleUpdateStoreDetailsIconClick: any;
  regeneratedQRCode: any;
  dialogRegenerateCodeHandler: any;
}

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RenderPOSDetailsDialog = (props: RenderPOSDetailsDialogInterface) => {
  const { classes, open, rowData, handleClose, handleUpdateStoreDetailsIconClick, regeneratedQRCode, dialogRegenerateCodeHandler } = props;
  const dialogPaperProps = { classes: { root: classes.dialogPaperPropsRoot }, square: true };

  const dispatch = useDispatch();

  const { error } = useSelector((state: AppState) => state.storeManagement);

  const [dialogState, setDialogState] = useState({
    loading: false,
    errors: {} as any,
    //
    openSnackbar: false,
    snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
    snackbarMessage: "",
    //
    store: "",
    autoAssignment: false,
    initialAssignmentAllowed: false,
    autoAssignmentExpiry: 0,
    serviceTime: 0,
    maxOrderAssignmentToDriver: 0,
    searchDistance: 0,
    maxOperatingDistance: 0,
    preferredVehicle: [],
    category: [],
  });

  const isStandardIncluded = () => {
    return rowData && rowData.deliveryType && Array.isArray(rowData.deliveryType) && rowData.deliveryType.indexOf("STANDARD") !== -1;
  };

  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item className={classes.titleContainerItem}>
          <Typography className={classes.titleSpanStyle}>{`Store Information`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleSwitch = (name: "autoAssignment" | "initialAssignmentAllowed") => {
    setDialogState({ ...dialogState, [name]: Boolean(!dialogState[name]) });
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    if (event?.target) {
      const { name, value } = event.target;
      const updatedErrors = { ...dialogState.errors };
      delete updatedErrors[name];
      setDialogState({ ...dialogState, [name]: value, errors: updatedErrors });
    }
  };

  const updateStoreDetails = async (updatedFields: any) => {
    const newState = { store: dialogState.store, ...updatedFields };
    const payload = createUpdateStoreRecordsSlicePayload(_.cloneDeep(newState));
    const data: any = await dispatch(updateStoreRecordsSlice(payload));
    if (data?.payload?.status === "SUCCESS" || data?.status === "SUCCESS") {
      setDialogState((prevState) => ({ ...prevState, loading: false, errors: {}, openSnackbar: true, snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS, snackbarMessage: "Store record updated!" }));
      handleUpdateStoreDetailsIconClick(true);
    }
  };

  const isChangesFound = () => {
    const updatedFields = {} as any;
    Object.entries(dialogState).forEach(([key, value]) => {
      if (key in rowData && value !== rowData[key]) {
        updatedFields[key] = value;
      }
    });

    if (Object.keys(updatedFields)?.length !== 0) {
      return false;
    } else if (isStandardIncluded() && isUndefined(rowData?.category) && dialogState?.category?.length !== 0) {
      return false;
    }
    return true;
  };

  const validateStoreDetails = () => {
    setDialogState((prevState) => ({ ...prevState, loading: true, errors: {} }));
    const { autoAssignmentExpiry, serviceTime, maxOrderAssignmentToDriver, searchDistance, maxOperatingDistance, preferredVehicle, category } = dialogState;
    const errors = {} as any;

    // Validation logic: Check for invalid values and add to errors
    if (isUndefined(autoAssignmentExpiry) || isNull(autoAssignmentExpiry) || Number(autoAssignmentExpiry) < 0) {
      errors.autoAssignmentExpiry = "Auto-assignment expiry must be a non-negative number.";
    }
    if (isUndefined(serviceTime) || isNull(serviceTime) || Number(serviceTime) < 0) {
      errors.serviceTime = "Service time must be a non-negative number.";
    }
    if (isUndefined(maxOrderAssignmentToDriver) || isNull(maxOrderAssignmentToDriver) || Number(maxOrderAssignmentToDriver) < 0) {
      errors.maxOrderAssignmentToDriver = "Max order assignment to driver must be a non-negative number.";
    }
    if (isUndefined(searchDistance) || isNull(searchDistance) || Number(searchDistance) < 0) {
      errors.searchDistance = "Search distance must be a non-negative number.";
    }
    if (isUndefined(maxOperatingDistance) || isNull(maxOperatingDistance) || Number(maxOperatingDistance) < 0) {
      errors.maxOperatingDistance = "Max operating distance must be a non-negative number.";
    }

    const errorMessge = "Preferred vehicle must be an array.";
    if (!Array.isArray(preferredVehicle) || isNull(preferredVehicle) || preferredVehicle?.length === 0) {
      errors.preferredVehicle = errorMessge;
    } else if (preferredVehicle.length === 1 && preferredVehicle[0] === AppConstants.SELECT_NONE.value) {
      errors.preferredVehicle = errorMessge;
    }

    const categoryErrorMessage = "Category is invalid.";
    if (isStandardIncluded()) {
      if (!Array.isArray(category) || isNull(category) || category?.length === 0) {
        errors.category = categoryErrorMessage;
      } else if (category.length === 1 && category[0] === AppConstants.SELECT_NONE.value) {
        errors.category = categoryErrorMessage;
      }
    }

    // If there are errors, update the dialogState with errors and stop further processing
    if (Object.keys(errors).length > 0) {
      setDialogState((prevState) => ({ ...prevState, loading: false, errors }));
      return;
    }

    // Check for differences from the original data
    const updatedFields = {} as any;
    Object.entries(dialogState).forEach(([key, value]) => {
      if (key in rowData && value !== rowData[key]) {
        updatedFields[key] = value;
      }
    });

    if (Object.keys(updatedFields)?.length === 0) {
      setDialogState((prevState) => ({
        ...prevState,
        loading: false,
        errors: {},
        openSnackbar: true,
        snackbarType: AppConstants.SNACKBAR.TYPES.ERROR,
        snackbarMessage: "No changes found for update",
      }));
      return;
    } else {
      updateStoreDetails(updatedFields);
    }
  };

  const handleSnackbarClose = () => {
    setDialogState((prevState) => ({ ...prevState, openSnackbar: false, snackbarMessage: "" }));
  };

  const renderEditPosDetails = () => {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.switchSectionContainer}>
          <Typography className={classes.switchSectionSpanStyle}>{`Auto Assignment`}</Typography>
          <SwitchButton size="large" checked={dialogState.autoAssignment} handleChange={() => handleSwitch("autoAssignment")} onClick={(e: any) => e.stopPropagation()} />
        </Grid>
        <Grid item xs={12} className={classes.switchSectionContainer}>
          <Typography className={classes.switchSectionSpanStyle}>{`Initial Assignment Allowed`}</Typography>
          <SwitchButton size="large" checked={dialogState.initialAssignmentAllowed} handleChange={() => handleSwitch("initialAssignmentAllowed")} onClick={(e: any) => e.stopPropagation()} />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Auto Assignment Expiry`}</Typography>
          <TextBox
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={dialogState.autoAssignmentExpiry}
            error={!isUndefined(dialogState?.errors?.autoAssignmentExpiry)}
            helperText={!isUndefined(dialogState?.errors?.autoAssignmentExpiry) ? dialogState?.errors?.autoAssignmentExpiry : ""}
            textBoxId="autoAssignmentExpiry"
            name="autoAssignmentExpiry"
            type="number"
            placeholderText="Auto Assignment Expiry"
          />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Service Time`}</Typography>
          <TextBox
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={dialogState.serviceTime}
            error={!isUndefined(dialogState?.errors?.serviceTime)}
            helperText={(!isUndefined(dialogState?.errors?.serviceTime) && dialogState?.errors?.serviceTime) || ""}
            textBoxId="serviceTime"
            name="serviceTime"
            type="number"
            placeholderText="Service Time"
          />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Max Order Assignment to Driver`}</Typography>
          <TextBox
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={dialogState.maxOrderAssignmentToDriver}
            error={!isUndefined(dialogState?.errors?.maxOrderAssignmentToDriver)}
            helperText={(!isUndefined(dialogState?.errors?.maxOrderAssignmentToDriver) && dialogState?.errors?.maxOrderAssignmentToDriver) || ""}
            textBoxId="maxOrderAssignmentToDriver"
            name="maxOrderAssignmentToDriver"
            type="number"
            placeholderText="Max Order Assignment to Driver"
          />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Search Distance`}</Typography>
          <TextBox
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={dialogState.searchDistance}
            error={!isUndefined(dialogState?.errors?.searchDistance)}
            helperText={(!isUndefined(dialogState?.errors?.searchDistance) && dialogState?.errors?.searchDistance) || ""}
            textBoxId="searchDistance"
            name="searchDistance"
            type="number"
            placeholderText="Search Distance"
          />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Max Operating Distance`}</Typography>
          <TextBox
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={dialogState.maxOperatingDistance}
            error={!isUndefined(dialogState?.errors?.maxOperatingDistance)}
            helperText={(!isUndefined(dialogState?.errors?.maxOperatingDistance) && dialogState?.errors?.maxOperatingDistance) || ""}
            textBoxId="maxOperatingDistance"
            name="maxOperatingDistance"
            type="number"
            placeholderText="Max Operating Distance"
          />
        </Grid>
        <Grid item xs={12} className={classes.valueSectionContainer}>
          <Typography className={classes.editScreenHeaderSpanStyle}>{`Preferred Vehicle`}</Typography>
          <SelectBox
            fullWidth
            multiple
            handleFormikChange={handleChange}
            value={dialogState.preferredVehicle}
            error={!isUndefined(dialogState?.errors?.preferredVehicle)}
            helperText={(!isUndefined(dialogState?.errors?.preferredVehicle) && dialogState?.errors?.preferredVehicle) || ""}
            id="preferredVehicle"
            inputProps={{ name: "preferredVehicle", id: "preferredVehicle" }}
            name="preferredVehicle"
            items={[
              { name: "BIKE", value: "BIKE" },
              { name: "CAR", value: "CAR" },
            ]}
          />
        </Grid>
        {isStandardIncluded() && (
          <Grid item xs={12} className={classes.valueSectionContainer}>
            <Typography className={classes.editScreenHeaderSpanStyle}>{`Category`}</Typography>
            <SelectBox
              fullWidth
              multiple
              handleFormikChange={handleChange}
              value={dialogState.category}
              error={!isUndefined(dialogState?.errors?.category)}
              helperText={(!isUndefined(dialogState?.errors?.category) && dialogState?.errors?.category) || ""}
              id="category"
              name="category"
              inputProps={{ name: "category", id: "category" }}
              items={[
                { name: "FOOD", value: "FOOD" },
                { name: "NONFOOD", value: "NONFOOD" },
              ]}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const getDetailsContent = () => {
    return (
      <>
        <RenderPOSDetails classes={classes} rowData={rowData} />
        {renderEditPosDetails()}
        <RenderPOSQRCodeDetails classes={classes} rowData={rowData} regeneratedQRCode={regeneratedQRCode} dialogRegenerateCodeHandler={dialogRegenerateCodeHandler} />
      </>
    );
  };

  const getDetailsDialogActions = () => {
    return (
      <Grid className={classes.dialogButtonsContainer} container spacing={1}>
        <Grid item xs={6}>
          <Button
            className={`${classes.dialogButtonsStyle} dialogBtn secondary`}
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            {AppConstants.BUTTONS.CANCEL}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button className={`${classes.dialogButtonsStyle} dialogBtn primary`} variant="contained" onClick={() => validateStoreDetails()} disabled={isChangesFound()}>
            {AppConstants.BUTTONS.SAVE}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const updateDefaultState = () => {
    if (rowData && rowData.store) {
      if (isStandardIncluded()) {
        setDialogState({
          ...dialogState,
          loading: false,
          store: rowData?.store || "",
          autoAssignment: rowData?.autoAssignment || false,
          initialAssignmentAllowed: rowData?.initialAssignmentAllowed || false,
          autoAssignmentExpiry: rowData?.autoAssignmentExpiry || 0,
          serviceTime: rowData?.serviceTime || 0,
          maxOrderAssignmentToDriver: rowData?.maxOrderAssignmentToDriver || 0,
          searchDistance: rowData?.searchDistance || 0,
          maxOperatingDistance: rowData?.maxOperatingDistance || 0,
          preferredVehicle: rowData?.preferredVehicle || [],
          category: rowData?.category || [],
        });
      } else {
        setDialogState({
          ...dialogState,
          loading: false,
          store: rowData?.store || "",
          autoAssignment: rowData?.autoAssignment || false,
          initialAssignmentAllowed: rowData?.initialAssignmentAllowed || false,
          autoAssignmentExpiry: rowData?.autoAssignmentExpiry || 0,
          serviceTime: rowData?.serviceTime || 0,
          maxOrderAssignmentToDriver: rowData?.maxOrderAssignmentToDriver || 0,
          searchDistance: rowData?.searchDistance || 0,
          maxOperatingDistance: rowData?.maxOperatingDistance || 0,
          preferredVehicle: rowData?.preferredVehicle || [],
        });
      }
    }
  };

  useEffect(() => {
    setDialogState((prevState) => ({ ...prevState, loading: false, errors: {}, openSnackbar: true, snackbarType: AppConstants.SNACKBAR.TYPES.ERROR, snackbarMessage: error }));
  }, [error]);

  useEffect(() => {
    updateDefaultState();
  }, []);

  return (
    <>
      <CustomDialog open={open} TransitionComponent={Transition} PaperProps={dialogPaperProps} title={getTitleContent()} content={getDetailsContent()} actions={getDetailsDialogActions()} handleClose={handleClose} />
      <CustomSnackbar open={dialogState.openSnackbar} handleClose={handleSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={dialogState.snackbarMessage} type={dialogState.snackbarType} />
    </>
  );
};

export default React.memo(RenderPOSDetailsDialog);
