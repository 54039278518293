import React from 'react';

import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { customerCommunicationFeatureEnabledForCountries } from './config/Api.config';
import {isFeatureEnabledForCountry} from './utils/helpers.utils'

import AppConstants from './constants';

import NotFound from './common/NotFoundPage';
import UserLogin from './Login';
import RoutePlanning from './RoutePlanning';
import CreateRoute from './RoutePlanning/CreateRoute';
import ValetTrolleyCreateRoute from './ValetTrolley/VTCreateRoute';
import CarrefourDeliveryCreateRoute from './CarrefourDelivery/CarrefourDeliveryCreateRoute';
import ReconciliationDashboard from "./Dashboard/ReconciliationDashboard";
import PerformanceDashboard from "./Dashboard/PerformanceDashboard";
import HubWiseDashboard from "./Dashboard/HubwiseDashboard";
import Users from './Users';
import DelveOrderList from './Delve/OrderList';
import RouteTracking from './RouteTracking';
import ValetTrolley from './ValetTrolley';
import Hubs from 'Hubs';
import POSManagement from './POSManagement';
import ClickAndCollect from 'ClickAndCollect';
import CarrefourDelivery from './CarrefourDelivery';
import AccessDenied from './common/AccessDenied';
import Express from './Express';
import Drivers from './DriverManagement';
import CODPortal from './CODPortal/express';
import StdCodPortal from './CODPortal/standard';
import DelveDashboard from './Delve/Dashboard';
import Returns from './Cams-widgets/returns'
import CamsDashboard from './Cams-widgets/camsDashboard';
import ExceptionsDeliveries from './Cams-widgets/exception';
import C4NowOnDemand from './Cams-widgets';
import StagingArea from './StagingArea'
import CustomerCommunication from './CustomerCommunication'
import StoreManagementPage from './StoreManagement/StoreManagementPage';

interface RoutesProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
  isDispatcher: boolean;
  isCHDManager: boolean;
  isCollectionPointManager: boolean;
  isVTManager: boolean;
  isQCManager: boolean;
  isOnlyExpressDispatcher: boolean;
  isOnlyStandardDispatcher: boolean;
  isLastMileViewer: boolean;
  isOnlyStdLastMileViewer: boolean;
  isOnlyExpLastMileViewer: boolean;
  role: any;
  countryCode: any;
};

interface GuardedRouteProps extends RouteProps {
  routeGuards?: RouteGuards;
  extraProps?: any;
  isCHDManager?: any;
  role: any;
}

interface RouteGuards {
  [key: string]: boolean;
}

const RouteGuards = (guards: RouteGuards | undefined) => {
  let isActive = false;
  if (guards) {
    isActive = true;
    for (let key in guards) {
      if (!guards[key]) {
        isActive = false;
        break;
      }
    }
  }
  return isActive;
}

const checkAuthRedirection = (role: any) => {
  switch (role) {
    case AppConstants.ADMIN_ROLE:
      return AppConstants.ROUTES.PLANNING;
    case AppConstants.CPM_ROLE:
      return AppConstants.ROUTES.CLICKNCOLLECT;
    case AppConstants.CHD_ROLE:
      return AppConstants.ROUTES.CARREFOUR_HOME_DLVRY;
    case AppConstants.VT_MANAGER_ROLE:
      return AppConstants.ROUTES.VALETTROLLEY;
    case AppConstants.QC_MANAGER_ROLE:
      return AppConstants.ROUTES.DELVE_DASHBOARD;
    case AppConstants.EXPRESS_DISPATCHER_ROLE:
      return AppConstants.ROUTES.CARREFOUR_NOW;
    case AppConstants.DISPATCHER_ROLE:
      return AppConstants.ROUTES.HOME;
    case AppConstants.EXPRESS_VIEWER_ROLE:
      return AppConstants.ROUTES.CARREFOUR_NOW;
    default:
      return AppConstants.ROUTES.HOME
  }
}

const AuthenticatedRoute = (props: GuardedRouteProps) => {
  const { component: Component, routeGuards, extraProps, role, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => (RouteGuards(routeGuards) === true ? Component ? <Component {...{ ...props, ...extraProps }} /> : < Redirect to={{ pathname: checkAuthRedirection(role) }} /> : <Redirect to={{ pathname: AppConstants.ROUTES.LOGIN }} />)
      }
    />
  );
}


const UnauthenticatedRoute = (props: GuardedRouteProps) => {
  const { component: Component, routeGuards, extraProps, isCHDManager, role, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => (RouteGuards(routeGuards) === false ? Component ? <Component {...{ ...props, ...extraProps }} /> : <Redirect to={{ pathname: AppConstants.ROUTES.LOGIN }} /> : <Redirect to={{ pathname: checkAuthRedirection(role) }} />)}
    />
  );
};

const Routes = (props: RoutesProps) => {

  const {
    isLoggedIn,
    isAdmin,
    isDispatcher,
    isCHDManager,
    isCollectionPointManager,
    isVTManager,
    isQCManager,
    isOnlyExpressDispatcher,
    isOnlyStandardDispatcher,
    isLastMileViewer,
    isOnlyStdLastMileViewer,
    isOnlyExpLastMileViewer,
    role,
    countryCode,
  } = props;

  function checkRedirection() {
    switch (role) {
      case AppConstants.CPM_ROLE:
        return AppConstants.ROUTES.CLICKNCOLLECT;
      case AppConstants.CHD_ROLE:
        return AppConstants.ROUTES.CARREFOUR_HOME_DLVRY;
      case AppConstants.VT_MANAGER_ROLE:
        return AppConstants.ROUTES.VALETTROLLEY;
      case AppConstants.ADMIN_ROLE:
        return AppConstants.ROUTES.PLANNING;
      case AppConstants.QC_MANAGER_ROLE:
        return AppConstants.ROUTES.DELVE_DASHBOARD;
      case AppConstants.EXPRESS_DISPATCHER_ROLE:
        return AppConstants.ROUTES.CARREFOUR_NOW;
      case AppConstants.DISPATCHER_ROLE:
        return AppConstants.ROUTES.HOME;
      case AppConstants.EXPRESS_VIEWER_ROLE:
        return AppConstants.ROUTES.CARREFOUR_NOW;
      default:
        return AppConstants.ROUTES.HOME
    }
  }
  return (
    <Switch>
      <Redirect
        to={isLoggedIn ? checkRedirection() : AppConstants.ROUTES.LOGIN}
        path={AppConstants.ROUTES.INDEX}
        exact
      />
      {(isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn
          }}
          path={AppConstants.ROUTES.PLANNING}
          component={RoutePlanning}
          role={role}
        />}
      {(isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn
          }}
          path={AppConstants.ROUTES.EXPRESS}
          component={RoutePlanning}
          role={role}
        />}
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.CREATE_ROUTE}
        component={CreateRoute}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.VIEW_ROUTE}/:id`}
        component={CreateRoute}
        extraProps={{
          viewMode: true
        }}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.EDIT_ROUTE}/:id`}
        component={CreateRoute}
        extraProps={{
          editMode: true
        }}
        role={role}
      />
      {(isCHDManager || isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn
          }}
          path={AppConstants.ROUTES.CARREFOUR_HOME_DLVRY}
          component={CarrefourDelivery}
          role={role}
        />}
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.CARREFOUR_DELIVERY_CREATE_ROUTE}
        component={CarrefourDeliveryCreateRoute}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.CARREFOUR_DELIVERY_VIEW_ROUTE}/:id`}
        component={CarrefourDeliveryCreateRoute}
        extraProps={{
          viewMode: true
        }}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.CARREFOUR_DELIVERY_EDIT_ROUTE}/:id`}
        component={CarrefourDeliveryCreateRoute}
        extraProps={{
          editMode: true
        }}
        role={role}
      />
      {(isCollectionPointManager || isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn
          }}
          path={AppConstants.ROUTES.CLICKNCOLLECT}
          component={ClickAndCollect}
          role={role}
        />}
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={AppConstants.ROUTES.PERFORMANCE}
        component={PerformanceDashboard}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={AppConstants.ROUTES.RECONCILIATION}
        component={ReconciliationDashboard}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={AppConstants.ROUTES.HUBWISE}
        component={HubWiseDashboard}
        role={role}
      />
      {(isVTManager || isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn
          }}
          path={AppConstants.ROUTES.VALETTROLLEY}
          component={ValetTrolley}
          role={role}
        />}
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.VALET_TROLLEY_CREATE_ROUTE}
        component={ValetTrolleyCreateRoute}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.VALET_TROLLEY_VIEW_ROUTE}/:id`}
        component={ValetTrolleyCreateRoute}
        extraProps={{
          viewMode: true
        }}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={`${AppConstants.ROUTES.VALET_TROLLEY_EDIT_ROUTE}/:id`}
        component={ValetTrolleyCreateRoute}
        extraProps={{
          editMode: true
        }}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={[AppConstants.ROUTES.TRACKING , AppConstants.ROUTES.VT_CONFIG.Routes.Tracking]}
        component={RouteTracking}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isCHDManager || isCollectionPointManager || isVTManager || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={[AppConstants.ROUTES.HUBS , AppConstants.ROUTES.VT_CONFIG.Routes.Hubs]}
        component={Hubs}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isCHDManager || isCollectionPointManager || isVTManager || isOnlyExpressDispatcher || isLastMileViewer || isOnlyExpLastMileViewer
        }}
        path={AppConstants.ROUTES.POS}
        component={POSManagement}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isCHDManager || isCollectionPointManager || isVTManager || isOnlyExpressDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyExpLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={AppConstants.ROUTES.USERS}
        component={Users}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isQCManager || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer
        }}
        path={AppConstants.ROUTES.DELVE_DASHBOARD}
        component={DelveDashboard}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isQCManager || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer
        }}
        path={AppConstants.ROUTES.DELVE_ORDER_LIST}
        component={DelveOrderList}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyExpressDispatcher || isLastMileViewer || isOnlyExpLastMileViewer
        }}
        path={AppConstants.ROUTES.COD_PORTAL}
        component={CODPortal}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyStandardDispatcher || isLastMileViewer || isOnlyStdLastMileViewer
        }}
        path={AppConstants.ROUTES.STD_COD_PORTAL}
        component={StdCodPortal}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer || isOnlyExpressDispatcher || isOnlyStandardDispatcher
        }}
        path={AppConstants.ROUTES.ALL_ORDERS}
        component={C4NowOnDemand}
        role={role}
      />
       <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer || isOnlyExpressDispatcher || isOnlyStandardDispatcher
        }}
        path={AppConstants.ROUTES.RETURNS}
        component={Returns}
        role={role}
      />
        <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer || isOnlyExpressDispatcher || isOnlyStandardDispatcher
        }}
        path={AppConstants.ROUTES.DASHBOARDCAMS}
        component={CamsDashboard}
        role={role}
      />
        <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isLastMileViewer || isOnlyStdLastMileViewer || isOnlyExpLastMileViewer || isOnlyExpressDispatcher || isOnlyStandardDispatcher
        }}
        path={AppConstants.ROUTES.EXCEPTIONS_DELIVERIES}
        component={ExceptionsDeliveries}
        role={role}
      />
      {isOnlyExpressDispatcher &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn,
            isAdmin: isOnlyExpressDispatcher
          }}
          path={AppConstants.ROUTES.CARREFOUR_NOW}
          component={Express}
          role={role}
        />
      }
      {(isAdmin || isDispatcher || isLastMileViewer || isOnlyExpLastMileViewer) &&
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn,
            isAdmin: isAdmin || isDispatcher || isLastMileViewer || isOnlyExpLastMileViewer
          }}
          path={AppConstants.ROUTES.CARREFOUR_NOW}
          component={Express}
          role={role}
        />
      }
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.DRIVERS}
        component={Drivers}
        role={role}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.RESET_PWD}
        component={UserLogin}
        extraProps={{
          resetPwd: true
        }}
        role={role}
      />
      <UnauthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        path={AppConstants.ROUTES.LOGIN}
        component={UserLogin}
        role={role}
      />
       <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
        }}
        path={AppConstants.ROUTES.STAGING_AREA}
        component={StagingArea}
        role={role}
      />
       {isFeatureEnabledForCountry(
        customerCommunicationFeatureEnabledForCountries,
        countryCode
      ) && (
        <AuthenticatedRoute
          routeGuards={{
            auth: isLoggedIn,
            isAdmin: isAdmin,
          }}
          path={AppConstants.ROUTES.CUSTOMER_COMMUNICATION}
          component={CustomerCommunication}
          role={role}
        />
      )}
      <AuthenticatedRoute
        routeGuards={{ auth: isLoggedIn, isAdmin: isAdmin || isDispatcher }}
        path={AppConstants.ROUTES.STORE_MANAGEMENT}
        component={StoreManagementPage}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
        }}
        path={AppConstants.ROUTES.FLEET}
        component={NotFound}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn,
          isAdmin: isAdmin || isDispatcher || isOnlyStandardDispatcher || isOnlyExpressDispatcher
        }}
        path={AppConstants.ROUTES.ADMINISTRATION}
        component={NotFound}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        component={AccessDenied}
        role={role}
      />
      <AuthenticatedRoute
        routeGuards={{
          auth: isLoggedIn
        }}
        component={NotFound}
        role={role}
      />
    </Switch>
  )
};

export default Routes;
