import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, SvgIcon, Typography } from "@material-ui/core";
import { cloneDeep } from "lodash";

import { ReactComponent as editIcon } from "./../assets/icons/edit-icon.svg";
import { ReactComponent as qrCodeIcon } from "./../assets/icons/qr-code-icon.svg";

import Loader from "common/Loader";
import AppConstants from "../constants";
import GridWrapper from "../common/GridWrapper";
import RenderQRCodeDetailsModal from "./common/RenderQRCodeDetailsModal";
import RenderPOSDetailsDialog from "./common/RenderPOSDetailsDialog";

import { useStyle } from "./StoreManagement.styles";
import { useSelector } from "react-redux";
import { AppState } from "../config/redux/reducers";
import { CellParams, RowData } from "../common/GridWrapper/DataTable";
import { fetchHubRecords, regenerateQR, resetRegenerateQR } from "./redux/StoreManagementSlice";
import { createFetchHubRecordsPayload, designPosListToShow } from "./common/response.transforms";
import { isArrayNonEmpty, isNull, isUndefined } from "../utils/helpers.utils";

interface StoreManagementPageInterface {}

const StoreManagementPage = (props: StoreManagementPageInterface) => {
  const {} = props;

  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const classes = useStyle();
  const onLoadRef = useRef(true);

  const { countryCode } = useSelector((state: AppState) => state.common);
  const { loading, storeData, regeneratedQRCode, isQRRegenerated } = useSelector((state: AppState) => state.storeManagement);

  const [posListToShow, setPosListToShow] = useState([]);
  const [appState, setAppState] = useState({
    userSearchValue: "",
    openSnackbar: false,
    snackbarType: AppConstants.SNACKBAR.TYPES.SUCCESS,
    snackbarMessage: "",
    currentRowData: {},
    openEditStoreDialog: false,
    openViewQRCodeDialog: false,
  });
  const [tableWrapperData, setTableWrapperData] = useState({
    ...AppConstants.STORE_MANAGEMENT_CONSTANT.TABLE_WRAPPER_DATA,
    HeaderData: AppConstants.STORE_MANAGEMENT_CONSTANT.TABLE_WRAPPER_DATA.HeaderData.map((header: any) => {
      let newHeader = { ...header };
      if (newHeader.field === "store") {
        newHeader.renderCell = (params: CellParams) => <Typography className="hubIdLink">{`${params.value}`}</Typography>;
      }
      if (newHeader.field === "actions") {
        newHeader.renderCell = (params: CellParams) => (
          <Grid container className={classes.actionsHeader}>
            <Grid className="actionItem" item>
              <SvgIcon className="icon" viewBox="0 0 24 24" onClick={() => handleEditStoreDetailsIconClick(params?.rowData || {})} component={editIcon} />
            </Grid>
            <Grid item className="actionItem">
              <SvgIcon className="icon" viewBox="0 0 24 24" onClick={() => handleQRCodeDetailsIconClick(params?.rowData || {})} component={qrCodeIcon} />
            </Grid>
          </Grid>
        );
      }
      return newHeader;
    }),
    defaultFiltersObj: { ...AppConstants.STORE_MANAGEMENT_CONSTANT.TABLE_WRAPPER_DATA.defaultFiltersObj },
  });
  const [activeFilters, setActiveFilters] = useState({ ...tableWrapperData.defaultFiltersObj });

  const filterCallback = (filters: any, isLoadMore?: boolean) => {
    if (filters) {
      let payloadFilters = cloneDeep(filters);
      setActiveFilters(filters);
      const payload = createFetchHubRecordsPayload(payloadFilters, storeData, isLoadMore, countryCode);
      dispatch(fetchHubRecords(payload));
    }
  };

  /** */
  const handleEditStoreDetailsIconClick = (rowObj: any) => {
    setAppState({ ...appState, openViewQRCodeDialog: false, openEditStoreDialog: true, currentRowData: rowObj });
  };

  const handleCloseEditStoreDetailsIconClick = (refreshState?: boolean) => {
    setAppState({ ...appState, openViewQRCodeDialog: false, openEditStoreDialog: false, currentRowData: {} });
    if (isQRRegenerated) {
      dispatch(resetRegenerateQR());
      filterCallback(activeFilters);
    }
  };

  const handleUpdateStoreDetailsIconClick = (refreshState?: boolean) => {
    setAppState({ ...appState, openViewQRCodeDialog: false, openEditStoreDialog: false, currentRowData: {} });
    if (!isUndefined(refreshState)) {
      filterCallback(activeFilters);
    }
  };

  const handleQRCodeDetailsIconClick = (rowObj: any) => {
    setAppState({ ...appState, openEditStoreDialog: false, openViewQRCodeDialog: true, currentRowData: rowObj });
  };

  const handleCloseQRCodeDetailsIconClick = () => {
    setAppState({ ...appState, openEditStoreDialog: false, openViewQRCodeDialog: false, currentRowData: {} });
    if (isQRRegenerated) {
      dispatch(resetRegenerateQR());
      filterCallback(activeFilters);
    }
  };

  const searchFilterHandler = (data: { [key: string]: string }, updatedSearchValue: string, filters: any) => {
    const activeFilters = cloneDeep(filters);
    onLoadRef.current = false;
    setAppState({ ...appState, userSearchValue: updatedSearchValue });
    if (updatedSearchValue) {
      for (const searchKey in data) {
        if (data[searchKey]) {
          activeFilters.searchAttribute = { key: searchKey, value: data[searchKey] };
          filterCallback(activeFilters);
          return;
        }
      }
    }
  };

  const handleStoreIdClick = (params: CellParams) => {
    if (params.field === "store") {
      const rowObj = params.rowData ? params.rowData : ({} as RowData);
      setAppState({ ...appState, openViewQRCodeDialog: false, openEditStoreDialog: true, currentRowData: rowObj });
    }
  };

  const handleLoadMoreDisableState = () => {
    let loadMoreState = tableWrapperData.loadMore;
    loadMoreState.disabled = storeData.totalPages ? storeData.pageNumber + 1 >= storeData.totalPages : true;
    setTableWrapperData({ ...tableWrapperData, loadMore: loadMoreState });
  };

  const dialogRegenerateCodeHandler = (store: string) => {
    if (!isNull(store)) {
      dispatch(regenerateQR({ params: store }));
    }
  };

  const configureBasedOnCountryCode = () => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Set to false after the first render
      return; // Exit the useEffect early
    }

    if (countryCode) {
      filterCallback(activeFilters);
    }
  };

  const renderDataForSearchSection = () => {
    return (
      <Grid container className={classes.searchSectionContainer}>
        {/* <Typography className={classes.searchSectionlSpanStyle}>
          {`All `}
          <Typography className={classes.searchSectionBoldSpanStyle}>{`(${storeData?.results?.hubsCount?.ALL || 0})`}</Typography>
        </Typography> */}
        <Typography className={classes.searchSectionlSpanStyle}>
          {`Standard `}
          <Typography className={classes.searchSectionBoldSpanStyle}>{`(${storeData?.results?.hubsCount?.STANDARD || 0})`}</Typography>
        </Typography>
        <Typography className={classes.searchSectionlSpanStyle}>
          {`Express `}
          <Typography className={classes.searchSectionBoldSpanStyle}>{`(${storeData?.results?.hubsCount?.EXPRESS || 0})`}</Typography>
        </Typography>
      </Grid>
    );
  };

  useEffect(() => {
    if (storeData?.elements) {
      setPosListToShow(designPosListToShow(storeData) as any);
      setAppState({ ...appState, openViewQRCodeDialog: false, openEditStoreDialog: false, currentRowData: {} });
      handleLoadMoreDisableState();
    }
  }, [storeData]);

  useEffect(() => {
    configureBasedOnCountryCode();
  }, [countryCode]);

  return (
    <div className={classes.root}>
      {loading && <Loader></Loader>}
      <Grid className={classes.container} container>
        <Grid className="userItem content" item>
          <GridWrapper
            showHeader={false}
            checkboxSelection={false}
            headerData={tableWrapperData.HeaderData}
            rowData={posListToShow}
            activeFilter={activeFilters}
            loading={loading}
            searchTypes={tableWrapperData.SEARCH_TYPES}
            defaultFilter={tableWrapperData.defaultFiltersObj}
            title={tableWrapperData.title}
            disableSelectionOnClick={true}
            searchValue={appState.userSearchValue}
            loadMore={{ ...tableWrapperData.loadMore, rowCount: storeData.totalElements || 0 }}
            searchCallback={searchFilterHandler}
            filterGrid={filterCallback}
            onCellClick={handleStoreIdClick}
            showDataNextToSearchButton={true}
            renderDataForSearchSection={renderDataForSearchSection}
            callOnActionOnly={false}
          />
        </Grid>
        {appState.openViewQRCodeDialog && (
          <RenderQRCodeDetailsModal
            classes={classes}
            open={appState.openViewQRCodeDialog}
            rowData={appState.currentRowData}
            handleClose={handleCloseQRCodeDetailsIconClick}
            loading={loading}
            regeneratedQRCode={regeneratedQRCode}
            dialogRegenerateCodeHandler={dialogRegenerateCodeHandler}
          />
        )}
        {appState.openEditStoreDialog && (
          <RenderPOSDetailsDialog
            classes={classes}
            open={appState.openEditStoreDialog}
            rowData={appState.currentRowData}
            handleClose={handleCloseEditStoreDetailsIconClick}
            handleUpdateStoreDetailsIconClick={handleUpdateStoreDetailsIconClick}
            regeneratedQRCode={regeneratedQRCode}
            dialogRegenerateCodeHandler={dialogRegenerateCodeHandler}
          />
        )}
      </Grid>
    </div>
  );
};

export default React.memo(StoreManagementPage);
